// import React from 'react';
import React, {Component} from 'react';
// class MiCompo extends React.Component{
    class MiCompo extends Component{

    render(){

        let receta = {
            nombre: 'Pizza',
            ingredientes: ['Tomate', 'Queso', 'Jamón cocido'],
            calorias: 400
        }
        // comentario

        return (
            // <h1>Hola, soy el componente llamado: MiCompo</h1>
            // <h2>Estoy probando el componente</h2>   Esto debe estar en otra eti...
            // Sugerencia: usar <React.Fragment> instrucc-01--- instrucc-02... </React.Fragment> 
            <React.Fragment>
            <div className="mi-compo">
                <h1>Hola, soy el componente llamado: MiCompo</h1>
                <h2>Estoy probando el componente</h2>
            </div>

            <div className="mi-compo-2">
                <h2>{'Receta: ' + receta.nombre}</h2>
                <h2>{'Calorias: ' + receta.calorias}</h2>
                <ol>
                    {
                        receta.ingredientes.map((ingrediente, i) => {
                            return (
                                <li>
                                    {ingrediente}
                                </li>
                            )
                        })
                    }
                </ol>

            </div>
            </React.Fragment>
        )
    }
}

// Añadiendo comentario

export default MiCompo;