import React from 'react';

// un estado puede ser un conjunto de datos
let receta = {
    nombre: 'Pizza',
    ingredientes: ['Tomate', 'Queso', 'Jamón cocido'],
    calorias: 400
}

const MiCompoFun2 = (props) => {
    
    const [estado, cambiarEstado] = React.useState(2);
    const presionarBoton = () => {
        cambiarEstado (estado + (estado*2))
    }
    
    return(
    
    // Equivale a un fragmento
    <> 
        <p>Hola</p>
        <p>{props.nombre}</p>

        <p>{estado.toString()}</p>
        <p><button onClick={presionarBoton}>Cambio</button></p>

    </>

    )
    
}

// Finalmente
export default MiCompoFun2;